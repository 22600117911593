import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'

const tableRowsCount = 11;
 
const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#bff0fd',
},
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    height: 1024,
    width: 750
  },
  section: {
    margin: 1,
    padding: 10,
    flexGrow: 2,
    fontSize: 12

  },
  sectionHeader: {
    margin: 5,
    marginLeft: 100,
    padding: 10,
    flexGrow: 2,



  },
  sectionBody: {
    margin: 50,
    padding: 1,
    flexGrow: 1,
    fontSize: 12


  },
  sectionFooter: {
    marginTop: 200,
    flexGrow: 1,
    fontSize: 10,
    padding: 10,


  },
  logo: {
    width: 250,
    height: 250,
    marginLeft: 'auto',
    marginRight: 'auto'
}
});

  const InvoiceItemsTable = ({invoice}) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.items} />
        <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
        <InvoiceTableFooter items={invoice.items} />
    </View>
  );
  
  export default InvoiceItemsTable