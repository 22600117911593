import React from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import { Row, Col, Table, ResponsiveEmbed } from 'react-bootstrap';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Image from 'react-bootstrap/Image'
import 'bootstrap/dist/css/bootstrap.min.css';
import logom from './images/xtra.jpg'
import adamim from './images/logoas.png'
import Moment from 'moment';
import { Carousel } from 'react-responsive-carousel';
import InvoiceTitle from './components/reports/InvoiceTitle'
import BillTo from './components/reports/BillTo'
import InvoiceNo from './components/reports/InvoiceNo'
import InvoiceItemsTable from './components/reports/InvoiceItemsTable'
import InvoiceThankYouMsg from './components/reports/InvoiceThankYouMsg'
import Pdf from "react-to-pdf";
import InputMask from 'react-input-mask';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Time from 'react-time-format'

import fileDownload from 'js-file-download'
import moment  from 'moment'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import resim1 from '../src/images/resim_1.jpg'
import resim2 from '../src/images/resim_2.jpg'
import resim3 from '../src/images/resim_3.jpg'
import resim4 from '../src/images/resim_4.jpg'/*
import resim5 from '../src/images/resim_5.jpg'
import resim6 from '../src/images/resim_6.jpg' */



let yuksekliks = Window.innerWidth

const options = {
  orientation: 'portrait',
  format: "A4",
  putOnlyUsedFonts: true,



};
const refreshPage = () => {
  window.location.reload();
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    //height: 1024,
    //width: 750,
    fontSize: 18,
    fontWeight: "bold",


    textalign: "center"


  },
  section: {
    margin: 1,
    fontSize: 12,



  },
  sectionHeader: {
    margin: 5,
    flexGrow: 2,
    fontSize: 12,



  },
  sectionBody: {
    padding: 1,
    flexGrow: 1,
    fontSize: 12,


  },
  sectionFooter: {
    marginTop: 200,
    flexGrow: 1,
    fontSize: 12,


  },
  logo: {
    width: window.innerWidth / 2,
    height: window.innerHeight / 8,
    // marginRight: window.innerWidth/600,
    justifyContent: 'center',
    alignSelf: 'center',

  },
  adamim: {
    width: 274,
    height: 390,

  },
  total: {
    fontSize: 50,
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: window.height / 4,

  }
});
function SelamBayang(props) {
  return <label>Frau</label>;
}
function SelamBay(props) {
  return <label>Herr</label>;
}
function Cinsiyetinne(props) {
  const cinsiyet = props.cinsiyet;
  if (cinsiyet) {
    return <SelamBayang />;
  }
  return <SelamBay />;
}

const ref = React.createRef();
const min = 1;
const max = 100000;
const pdfvisible = null
const rand = min + (Math.random() * (max - min));
 


class App extends React.Component {

  

  state = {
    content: '',
    charLimit: 8,
    id: null,
    value: null,
    result_code: null,
    result_value: null,
    gender: '',
    firstname: null,
    lastname: null,
    street: null,
    postal_code: null,
    place: null,
    email: null,
    mobile: null,
    dob: null,
    pob: null,
    test_Hour: null,
    pdfvisible: 1

  }

  componentDidMount() {
    const { userID } = this.props.match.params;
    //const gunceltarih = moment(test_date, "YYYYMMDD").fromNow();
    axios.get('https://my.madilink.net/customers/' + JSON.stringify(userID))
      .then(
        response => this.setState({
          id: response.data.id,
          result_code: response.data.result_code,
          hashcode:response.data.hash_code,
          result_value: response.data.result_value,
          userIDs: userID,
          gender: response.data.gender,
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          street: response.data.street,
          postal_code: response.data.postal_code,
          place: response.data.place,
          email: response.data.email,
          mobile: response.data.mobile,
          dob: response.data.dob,
          pob: response.data.pob,
          test_date: response.data.test_date,
          test_Hour: response.data.test_date,
          gender: response.data.gender,
          gunceltarih : moment(response.data.test_date, "HH:MM").fromNow()
        }
        ), []
      );

  }




  handleOnSubmit = (e) => {
    e.preventDefault()
    if (this.state.content.length <= this.state.charLimit) {
      Swal.fire({ icon: 'success', text: 'Successful Submission' })
    } else {
      Swal.fire({ icon: 'error', text: 'Character Limit Exceeded' })
    }
  }
  render() {
    const { id, result_code, result_value, gender, test_date, test_Hour, firstname, lastname, sirketadi, dob, pob,gunceltarih,userID,hashcode } = this.state;


    const porn = () => {
      const userID = this.props.match.params;
 
      //style={{ textAlign: "center", marginTop: window.height / 4, paddingRight: window.innerWidth / 8, paddingLeft: window.innerWidth / 7 }}>
      if (result_value === "p" || result_value === "n") {
        return <Container > {/* style={{ justifyContent: "left", textAlign: "center", marginLeft: window.innerWidth/ 12-120, marginTop: window.innerHeight / 8, paddingRight: window.innerWidth / 4, fontSize: 25 ,ref={ref}}} */}


          {/*             <Image style={styles.logo} src={logom} />
*/}            <div xs={1}>
            <Row >
              <br />
              <br />
              <Col xs={12} style={{ fontSize: 15, textAlign: "center", fontWeight: "bold" }}>Durchführung eines SARS-CoV-2 PoC-Antigentests</Col>
              <br />
              <br />



            </Row>


          </div>

          <br />

          <Row md={2} lg={2}  style={{ justifyContent: "left", textAlign: "left", fontSize: 16, marginLeft: window.innerWidth / 12 - 50 }}>
            <br />
            <Col xs={6} sm={6}>Name:</Col><Col>{firstname} {lastname}</Col>
            <Col xs={6} sm={6}>Geburtsdatum:</Col><Col><Time value={dob} format="DD-MM-YYYY" style={{ color: 'black' }} /></Col>

            <Col xs={6} sm={6} >Geburtsort:</Col>
            <Col xs={6} sm={6} > {pob}</Col>
            <Col xs={6} sm={6}>Testdatum:</Col>
            <Col xs={6} sm={6} ><Time value={test_date} format="DD-MM-YYYY" style={{ color: 'black' }} /></Col>

            <Col xs={6} sm={6}>Testuhrzeit:</Col>

            <Col xs={6} sm={6}>{<Time value={test_date} format="hh:mm" style={{ color: 'black' }} />}</Col> 

            <Col xs={6} sm={6}>Testvariante:</Col>
            <Col xs={6} sm={6} >Nasenabstrich (nasal swab)</Col>


          </Row>
          <br />
          <br />



          <Col xs={12} style={{ fontSize: 16, fontWeight: "bold" }}>Ergebnis</Col>
          <br />

          <Row md={1} lg={1} style={{ fontSize: 16, textAlign: "center" }}>


            <Col xs={12}>Negativ (n) oder Positiv (p)  : <label style={{ color: 'red' }}>{result_value}</label></Col>



          </Row>
          <br />
          <Col>Ihr Testzentrum </Col>
          <br />

          <button onClick={refreshPage}>Aktualisieren</button>
          <br />
          <br />



          <button>
             <a href={"https://teststation.rokotheke.de/download.php?hash="+hashcode}>Download</a>
          </button>
        </Container>

        

      }

      if (result_value === "x") {

        return <div >
          <h3>ID : {result_code}</h3>
          <button onClick={refreshPage}>Aktualisieren</button>
          <br /><br />

          <div >
            <Row>
              <Col style={{ backgroundColor: "red" }}> <label style={{ fontWeight: "22", color: "white", fontWeight: "bolder" }}> Bitte legitimieren Sie Ihre Anmeldung mit ihrem Personalausweis, 15 Minuten nach der Durchführung des Testes wird das Ergebnis zum Download bereitstehen!</label> <br />
              </Col>
            </Row>
          </div>

          <br />
          <div xd={1}>
            <Carousel xs={12} ld={12} md={12} autoPlay={true} itemSize={10} width={Window.innerWidth / 2} showArrows={false}
              showIndicators={false} showStatus={false} dynamicHeight={true} swiping={true} showThumbs={false} infiniteLoop={true} interval={10000} centerMode={false}>

              <div>
                <img alt="" src={resim1} />
              </div>
              <div>
                <img alt="" src={resim2} />
              </div>
              <div>
                <img alt="" src={resim3} />
              </div>
              <div>
                <img alt="" src={resim4} />
              </div>
              
            </Carousel>
          </div>

        </div>
      }

    }

    const pdfcontrol = () => {

      if (result_value === "p" || result_value === "n") {
        return <div>
          <div style={{ textAlign: "center", marginTop: window.height / 4, paddingRight: window.innerWidth / 8, paddingLeft: window.innerWidth / 8 }}>

            <label>  ID: {result_code}   </label>
            <br />

          </div>
          <br />
          <Container style={{ textAlign: "center", marginTop: window.height / 4, paddingRight: window.innerWidth / 8, paddingLeft: window.innerWidth / 7 }}>

            <Row>
              <Col xs={12} style={{ backgroundColor: "green" }}>
                <label style={{ fontWeight: "22", color: "white", fontWeight: "bolder" }}>Ergebnis liegt vor</label> <br />
              </Col>
            </Row>
          </Container>
        </div>


      }

      if (result_value === null) {
        return <div xd={Window.innerWidth / 12} md={Window.innerWidth / 12} lg={Window.innerWidth / 4}>
          <h1>Error PAGE</h1>
          <Carousel xs={12} ld={12} md={12} autoPlay={true} itemSize={Window.innerWidth / 2} width={Window.innerWidth / 8} showArrows={false}

            showIndicators={false} showStatus={false} dynamicHeight={true} swiping={true} showThumbs={false} infiniteLoop={true} interval={10000} centerMode={false}>

            <div>
              <img alt="" src={resim1} />
            </div>
            <div>
              <img alt="" src={resim2} />
            </div>
            <div>
              <img alt="" src={resim3} />
            </div>
            <div>
              <img alt="" src={resim4} />
            </div>
            
           
          </Carousel>
        </div>
      }
    }



    return (

      /*xs = Tabletten ve daha küçük ekranlar (Telefonlar)
     sm = Tablet ve laptop aralığı
     md = Laptop ve Masaüstü aralığı
     lg = Masaüstü ve üstü cihazlar (Masaüstü PC, 2k ve 4k Tvler)*/


      <div xs={1} style={{ textAlign: "center", marginTop: window.height / 4, paddingRight: window.innerWidth / 8, paddingLeft: window.innerWidth / 7 }}>


        {pdfcontrol()}
        <br />

        {
          porn()
        }

      </div>
    );

  }
}
export default App;
