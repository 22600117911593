import React, { Component } from 'react'
import '../icerik/login.css'

export default class body extends Component {
    render() {
        return (
            <div className="login">
                <text >Username</text>
                <input name="username"></input>
                <br/>
                <br/>

                <text>Password</text>
                <input name="enigma"></input>
                <br/>
                <br/>
                <button name="login">login</button>

            </div>
        )
    }
}
//npm install react-validation validator