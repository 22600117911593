import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';



const styles = StyleSheet.create({
    
    reportTitle: {
        color: '#61dafb',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
   
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
        height: 1024,
        width: 750
    },
    section: {
        margin: 1,
         flexGrow: 2,
        fontSize: 12

    },
    sectionHeader: {
        margin: 5,
         flexGrow: 2,



    },
    sectionBody: {
        margin: 50,
         flexGrow: 1,
        fontSize: 12


    },
    sectionFooter: {
        marginTop: 200,
        flexGrow: 1,
        fontSize: 25,
 

    },
    logo: {
        width: 250,
        height: 250,
      }
});


 
export default class InvoiceTitle extends React.Component {
    render() {
        return (
            <View style={styles.reportTitle}>
        <Text >Durchführung eines SARS-CoV-2 PoC-Tests </Text>
        <br />
        <Text style={{fontWeight:"bold"}}> {this.props.sirketadi} Companny</Text>
        <br />
        <br />

        <Text style={{fontWeight:"bold"}}> Ergebnis </Text>


    </View>
        )
    }
}
