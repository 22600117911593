import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import "../../../src/App.css";
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    titleContainer:{
        flexDirection: 'row',
        marginTop: 12
    },
    reportTitle:{
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 50,
        textAlign: 'center',
        fontStyle: 'bold',
        
 
    },
    description: {
         textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
        fontSize: 50,



    },
    total: {
         textAlign: 'center',
        paddingRight: 8,
        fontWeight:"bold",
        fontSize: 50,



    },
    totalResult: {
          paddingRight: 8,
        fontWeight:"bold",
        color:"Red"
    },
  });


 

 
export default class InvoiceThankYouMsg extends React.Component {
    render() {
        return (
            <View style={styles.row}>
            <Text >Name der getesteten Person:</Text>
            <Text style={styles.total}>{this.props.firstname} {this.props.lastname}</Text>
            <br/>
            <Text >Geburtsdatum:</Text>
            <Text style={styles.total}> {this.props.Geburtsdatum}</Text>
            <br/>
            <Text >Geburtsort:</Text>
            <Text style={styles.total}> {this.props.Geburtsort}</Text>
            <br/>
            <Text >Testdatum:</Text>
            <Text style={styles.total}> {this.props.Testdatum}</Text>
            <br/>
            <Text >Testuhrzeit:</Text>
            <Text style={styles.total}> {this.props.Testuhrzeit}</Text>
            <br/>
            <Text >Testvariante: </Text>
            <Text style={styles.total}>Nasenabstrich (nasal swap)</Text>
            <br/>
            <Text style={styles.total}>Negativ (n) oder Positiv (p)  : </Text>
              <Text  style={styles.totalResult}>{this.props.Result} </Text>

            <br/>
            <Text style={styles.total}>Diese Bescheinigung ist für einen Zeitraum von 24 Stunden nach der Testung gültig</Text>
            <br/>
            <Text style={styles.total}>Mit freundlichen Grüßen</Text>
            <br/>
            <Text style={styles.total}>im Auftrag</Text>
        
        </View>
        )
    }
}
